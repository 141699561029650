<template>
  <div class="reset-password">
    <form ref="authForm" class="login-form" @submit.prevent="onSubmitForm">
      <h1 class="auth-title">Forgot password?</h1>
      <p class="auth-subtext">
        Enter your e-mail. We'll send you a link to reset your password
      </p>

      <AppField
        label="Enter your e-mail"
        type="email"
        required
        :isInvalid="$v.email.$error"
        @input="setField($event, 'email')"
      >
        <template v-slot:error>
          <p v-if="!$v.email.required">This field is required</p>
          <p v-if="!$v.email.email">Incorrect email format</p>
        </template>
      </AppField>

      <AppButton
        title="Reset Password"
        class="form-btn"
        className="primary"
        type="submit"
        :pending="pending"
      />
    </form>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { authMixin } from "@/mixins/AuthMixin";

export default {
  name: "ResetPassword",
  mixins: [authMixin],

  data() {
    return {
      email: "",
    };
  },

  validations: {
    email: {
      required,
      email,
    },
  },

  methods: {
    onSubmitForm() {
      const payload = {
        email: this.email,
      };

      this.sendData(payload, "auth/resetPass");
    },
  },
};
</script>

<style scoped lang="scss" src="./index.scss" />
